import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import Header from '../common/Header'

const Clientes = () => {
  return (
    <Container fluid className='p-0' >
      <Header urlImg='./images/headers/clientes.jpg' titulo='Clientes'/>
      <Container className='section__container'>
        <Row className='justify-content-lg-center section__container--row'>
          <Col xs={12} lg={8} className='section__container--col'>
          <div className='row pb-1 pt-0'>
                    <div className="gallery__cont p-2 pb-4">
                        <div className="column">
                            <img src="images/clientes/bgh.jpg" className="d-block" alt=""/>
                            <img src="images/clientes/koinor.jpg" className="d-block" alt=""/>
                            <img src="images/clientes/lg.jpg" className="d-block" alt="" />
                        </div>
                        <div className="column">
                            <img src="images/clientes/mabe.jpg" className="d-block" alt=""  />
                            <img src="images/clientes/mademsa.jpg" className="d-block" alt=""  />
                            <img src="images/clientes/metalfrio.jpg" className ="d-block" alt="" data-img-mostrar="9" />
                        </div>
                        <div className="column">
                            <img src="images/clientes/miele.jpg" className ="d-block" alt="" data-img-mostrar="7" />
                            <img src="images/clientes/neba.jpg" className ="d-block" alt="" data-img-mostrar="8" />
                            <img src="images/clientes/samnsung.jpg" className="d-block" alt="" data-img-mostrar="6" />
                        </div>
                        
                        <div className="column">
                            <img src="images/clientes/samsung.jpg" className ="d-block" alt="" data-img-mostrar="10" />
                            <img src="images/clientes/whirlpool.jpg" className ="d-block" alt="" data-img-mostrar="11" />
                        </div>
                    </div>
                </div>
          </Col>
        </Row>
      </Container>
     
    </Container>
  )
}

export default Clientes