import { React } from 'react'
import { Navbar, Container, Nav} from 'react-bootstrap' 
import { Link } from 'react-router-dom'
import Logo from '../../assets/navbar-logo.svg'

import { useScrollPosition } from '../../hooks/useScrollPosition.js'

const NavbarSite = () => {

  const scrollPosition = useScrollPosition();  
  
  return (
    
      <Navbar bg="dark" variant="dark" expand="lg" sticky="top" className={scrollPosition > 0 ? 'navbar__scrolling': ''} >
        <Container >
          <Link to={'/'}>
             <Navbar.Brand className={scrollPosition > 0 ? 'navbar-brand__scrolling': ''}> 
                <img src={ Logo } alt="Harispe Agrimensura" />
              </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" >
             
            <Nav className="me-auto">
              <Link to={'/'} className={'nav-link'}>Inicio</Link>
              <Link to={'quienes-somos'} className={'nav-link'}>Quienes somos</Link>
              <Link to={'servicios'} className={'nav-link'}>Servicios</Link>
              <Link to={'proovedores'} className={'nav-link'}>Proveedores</Link>
              <Link to={'clientes'} className={'nav-link'}>Clientes</Link>
              <Link to={'contacto'} className={'nav-link'}>Contacto</Link>
            </Nav>

          </Navbar.Collapse>
        </Container>
     </Navbar>
  
  )
}

export default NavbarSite