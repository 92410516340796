import './App.css';
import NavbarSite from '../src/components/common/NavbarSite.jsx'
import NavbarSiteLine from '../src/components/common/NavbarSiteLine.jsx'
import {Routes, Route, HashRouter } from 'react-router-dom';
import Home from './components/Home';
import Footer from '../src/components/Footer'

import Clientes from './components/sections/Clientes.jsx'
import Servicios from './components/sections/Servicios.jsx'
import QuienesSomos from './components/sections/QuienesSomos.jsx'
import Contacto from './components/sections/Contacto.jsx'
import Proovedores from './components/sections/Proovedores';

function App() {
  return (
    <div className="container-fluid p-0">
      <HashRouter>
        <NavbarSite />
        <NavbarSiteLine />
        
          <Routes>
            <Route exact path='/' element={<Home/>} />
            <Route exact path='/quienes-somos/' element={ <QuienesSomos/> } />
            <Route exact path='/servicios/' element={ <Servicios/> } />
            <Route exact path='/proovedores/' element={ <Proovedores/> } />
            <Route exact path='/clientes/' element={ <Clientes/> } />
            <Route exact path='/contacto/' element={ <Contacto/> } />
          <Route path="*" element={<h2 className='text-center' style={{ paddingTop: 180, paddingBottom: 180}} >404 - Página no encontrada.</h2>}/>
          
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
