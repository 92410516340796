import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import {Container} from 'react-bootstrap'

import ButtonComponent from './ButtonComponent'
import './FooterFormulario.scss'
// import emailjs from 'emailjs-com';
import emailjs from 'emailjs-com';

// import React from 'react'
// import { Link } from 'react-router-dom'
// import { Container, Row, Col } from 'react-bootstrap'
// import LogoFooter from '../assets/logo-footer.svg'
// import FooterFormulario from './common/FooterFormulario'

const FooterFormulario = ( ) => {

    const [buttonSend, setButtonSend] =  useState(true)
    const [formSent, setFormSent] = useState(false)
    const [formSentNo, setFormSentNo] = useState(false)
    const [formSending, setFormSending] = useState(false)
    

    const {
        register, 
        resetField,
        handleSubmit, 
        formState: { errors }} = useForm({
        defaultValues:{
            userName: '',
            userTelephone: '',
            userEmail: '',
            userMessage: ''
        }
    });

    function sendEmail(data, e){

        console.log('sendEmail')
        e.preventDefault();
        setButtonSend(false);
        setFormSending(true)
        
        emailjs.sendForm('service_henkucb', 'template_64fkb7m', e.target, 'u46ocKQ3h_ngAAYuz').then(
            res => {
            
                resetField('userName'); resetField('userTelephone'); resetField('userEmail'); resetField('userMessage');
                console.log('CONTACTO FOOTER, ', res);
                    setFormSent(true)
                setFormSending(false)

                setTimeout( ()=>{
                    setFormSent(false);
                    setButtonSend(true);
                }, 3000)
                e.target.reset();
            }
        )
    }

    return (

        <Container className='pb-5'>
           
            <div className='container'>
                
                <form className='row d-flex justify-content-center' onSubmit={handleSubmit(sendEmail)} >
                    
                    <div className='col-md-5 formInputsBlock'>
                        
                        <input 
                            { ...register( 'userName', { 
                                required: 'El nombre solo puede contener letras y espacios.', 
                                pattern: { 
                                    value: /[A-Za-z]{3}/, 
                                    message: 'El nombre solo acepta letras y 3 como minimo.'
                                }
                            })}

                            placeholder='Nombre/Empresa *' className='formInput__box'  />
                        <p className='formInput__error'> {errors.userName?.message}</p>
                        
                        <input 
                            type = 'number'
                            { ...register('userTelephone', { 
                            required: 'El télefono solo puede contener numeros, guiones y parentesis y no menos de 8 numeros.'
                            , minLength: { value: 8, message: 'El nuemero de ser de mīnimo de 8 caracteres'} })} 
                            placeholder='Telefono *' className='formInput__box'  />
                        <p className='formInput__error'> {errors.userTelephone?.message}</p>
                        
                        <input 
                            
                            {...register('userEmail', { 
                                required: 'El solo puede contener letras, numeros, puntos y guiones bajos', 
                                pattern: { 
                                    value: /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]/ ,
                                    error: 'error de mail'
                                }   
                            })} 
                            
                            placeholder='Email *' className='formInput__box'  />
                        <p className='formInput__error'> {errors.userEmail?.message}</p>
                    </div>
                    
                    <div className='col-md-5'>
                        <textarea  { ...register('userMessage', { 
                                required: 'El mensaje debe tener mas de 30 caracteres', 
                                minLength: {
                                    value: 30, 
                                    message: 'El mensaje debe tener mas de 30 caracteres.'
                                } 
                            })} 
                            placeholder='Deje su mensaje. *' 
                            className='formInput__box formInput__textarea' >
                        </textarea>
                        <p className='formInput__error'> {errors.userMessage?.message}</p>
                    </div>

                
                    { formSending && <div className='text-center formInput__sent' style={{height: "40px", marginTop: "20px", color: "white"}}> 
                        <i className="fas fa-spinner fa-spin fa-3x"></i></div>
                    }
                    <div className='col-md-10 text-center pt-5 pb-0 mb-3'>
                        { formSent   &&  <p className='formInput__sent'>Formulario enviado. Proximamente nos comunicaremos.<br /> Muchas Gracias</p>}
                        { formSentNo &&  <p className='formInput__sentNo'>Se ha producido un ERROR al enviar el formulario, por favor intentelo nuevamente en unos minutos.<br /> Muchas Gracias</p>}
                        { buttonSend && <ButtonComponent  text={'Enviar'} classButton={'button-rounded--outline'} type='submit' /> }
                    </div>

                </form>
                
            </div > 
        </Container>
    )
}

export default FooterFormulario
