import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'

const CarouselProovedores = ( props ) => {
  
    const [index, setIndex] = useState(props.index);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
        
        return (

        <section>
            <Carousel activeIndex={index} className='carousel__box  animated fast fadeIn' indicators={ false } onSelect={ handleSelect } >
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-00.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-01.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-03.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-02.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-04.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-07.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-08.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-09.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-06.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-10.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-11.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-12.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-13.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-14.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-15.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-16.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-17.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-18.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-19.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-20.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-21.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-22.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-23.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-24.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-25.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-26.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proovedores/proovedores-27.jpg" alt="Proovedores de Irunfal" loading="lazy" />  </Carousel.Item>
            </Carousel> 
        </section>

    )
}

export default CarouselProovedores