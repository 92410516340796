import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ButtonComponent from './ButtonComponent'
import BannerDron from '../../assets/banner-dron-icon.svg'
import { Link } from 'react-router-dom'

const Banner = ({link }) => {

  return (

    <Container fluid className='banner__container' >
      <Row className='justify-content-lg-center banner__container--gutter'>
        <Col md lg="8"className='banner__container--col'>
          <div className='banner__text'>
            <i>
              Más de mil operaciones mensuales que se llevan a cabo desde nuestras oficinas en Montevideo, 
              República Oriental del Uruguay, nos dan reconocimiento y orgullo, 
              ofreciendo soluciones innovadoras con alto niveles de eficiencia 
              en las distintas situaciones que acontecen dentro del Comercio Exterior.</i></div>
        </Col>
          
      </Row>
    </Container>
    
  )
}

export default Banner