import React from 'react'
import { Carousel } from 'react-bootstrap' 
import ButtonComponent from './ButtonComponent'
import { Link } from 'react-router-dom'

const CarouselSite = () => {
  return (
    <Carousel className='container__top'>
      
      <Carousel.Item>
        <img
          className="d-block w-100 carousel__container--images"
          src="./images/carousel/container-operation-in-port-series.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='carousel__titulo'>¿Está listo para crecer? </h3>
          <p className='carousel__texto'>¡Con IRUNFAL es posible!</p>
          <Link to={'quienes-somos'}><ButtonComponent  text={'Mas Info'} link={'#'} classButton={'button-rounded'} /></Link>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 carousel__container--images"
          src="./images/carousel/industrial-port-and-container-yard.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='carousel__titulo'>¿Está listo para crecer?</h3>
          <p className='carousel__texto'>¡Con IRUNFAL es posible!.</p>
          <Link to={'servicios'}><ButtonComponent  text={'Mas Info'}  classButton={'button-rounded'}   /></Link>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 carousel__container--images "
          src="./images/carousel/products-made-in-china.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='carousel__titulo'>¿Está listo para crecer?</h3>
          <p className='carousel__texto'>¡Con IRUNFAL es posible!</p>
          <Link to={'clientes'}><ButtonComponent  text={'Mas Info'} link={'#'}  classButton={'button-rounded'}  /></Link>
        </Carousel.Caption>
      </Carousel.Item>

    </Carousel>
  )
}

export default CarouselSite