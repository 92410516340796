import React from 'react'
import { Container } from 'react-bootstrap'

const Header = ( { urlImg, titulo } ) => {
    console.log(urlImg)
  return (
    <Container fluid className='p-0 header__container animated fast fadeIn' style={{ backgroundColor: "rgba(0,0,0,0.1)"}}>
        
        <img
            className="header__image"
            src={ urlImg }
            alt={ titulo }
        />
  
        <div className='header__text'>
            <h2 className='header__text--title'>{titulo}</h2>
        </div>
        
    </Container>

  )
}

export default Header