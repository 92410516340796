import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Banner from './common/Banner'
import CardSite from './common/CardSite'
import CarouselSite from './common/CarouselSite'

const Home = () => {
  return (
    
    <Container fluid className='p-0' style={{ backgroundColor: "rgba(0,0,0,0.1)"}}>

        <CarouselSite />

        <Container fluid  className='bg-texture'>
          <Container className='cards__container'>
              <h2 className='section__title'>Servicios que realizamos</h2>
              <Row className='cards__container--row'>
                  <Col xs={12} md={4}>
                    <CardSite 
                      img='./images/atencion-personalizada.jpg' 
                      alt='Foto trabajando en relevamiento de propiedad horizontal'
                      title='Atención personalizada Integral' 
                      text='Desarrollamos por completo una propuesta integral de importación como también de exportación'
                      link='servicios'/>
                  </Col>
                  <Col xs={12} md={4}>
                  <CardSite 
                      img='./images/asesoria-tributaria.jpg' 
                      alt='Foto de equipo para realizar mensuras'
                      title='Asesoría tributaria' 
                      text='Como agentes autorizados de Comercio Exterior, nos encargamos de la coordinación y ejecución de todas las actividades que vinculen a la importación, exportación y Aduanas, con una amplia experiencia tributaria de cada páis.'
                      link='servicios'/>
                  </Col>
                  <Col xs={12} md={4}>
                  <CardSite 
                      img='./images/rastro-de-envios.jpg' 
                      alt='Foto trabajando en un estado parcelario '
                      title='Rastro de envios' 
                      text='Con Nuestra aplicación va a poder controlar su envío, vía aérea, vía marítima, vía terrestre, es la mejor opción de control de su transporte en tiempo real.                                      '
                      link='servicios'/>
                  </Col>
              </Row>
          </Container>
        </Container>       
    </Container>

  )
}

export default Home