import React from 'react'
// import FooterFormulario from '../common/FooterFormulario'
import { Container, Row } from 'react-bootstrap'
import HeaderMap from '../common/HeaderMap'

const Contacto = () => {
  return (
    <div className='contact__container'>
      <HeaderMap urlImg='./images/headers/clientes.jpg' titulo='Proovedores'/>
     
      <Container  className='p-5 pb-0'>
          <Row className='justify-content-lg-center'>
          <p className='qsomos__text--bloque contact__text'>En IRUNFAL lo asesoramos y planificamos estratégicamente un servicio global de exportación e importación, que se adapta al tamaño, medida y necesidad de su empresa ¡Consúltenos ahora sin compromiso!</p>
          </Row>
        </Container>
    </div>  
  )
}

export default Contacto