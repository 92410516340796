import React, {useState} from 'react'
import { Modal } from 'react-bootstrap'
import CarouselProovedores from './CarouselProovedores';

import './Gallery.css'

const GalleryProovedores = () => { 


    // --------- INICIO DE LOGICA DE CAROUSEL --------- //

    const [show, setShow] = useState(false);
    const [idImg, setIdImg] = useState();

    const handleClose = () => { setShow(false); }
    const handleShow = (idPic) => {
        setShow(true)
        setIdImg(idPic)
        console.log('setIdImg()')

    }

    // --------- FIN DE LOGICA DE CAROUSEL --------- //


    return (

        <div>
            <div className='container'>

                <Modal show={show} size='lg' onHide={handleClose} ackdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title>Nuestros proovedores</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <CarouselProovedores index={ idImg }/>
                    </Modal.Body>

                    <Modal.Footer className={'modal__desc'} >
                        <small> </small>
                    </Modal.Footer>
                </Modal>

                <div className='row pb-1 pt-0'>
                    
                    <div className="gallery__cont p-2 pb-4">
                        <div className="column">
                            <img onClick={ () => handleShow(0) } src="images/proovedores/proovedores-00.jpg" className="d-block" alt="Proovedores de Irunfal" data-img-mostrar="0" />
                            <img onClick={ () => handleShow(2) } src="images/proovedores/proovedores-03.jpg" className="d-block" alt="Proovedores de Irunfal" data-img-mostrar="2" />
                            <img onClick={ () => handleShow(3) } src="images/proovedores/proovedores-02.jpg" className="d-block" alt="Proovedores de Irunfal" data-img-mostrar="3" />
                            <img onClick={ () => handleShow(1) } src="images/proovedores/proovedores-01.jpg" className="d-block" alt="Proovedores de Irunfal" data-img-mostrar="1" />
                            <img onClick={ () => handleShow(4) } src="images/proovedores/proovedores-04.jpg" className="d-block" alt="Proovedores de Irunfal" data-img-mostrar="4" />
                            <img onClick={ () => handleShow(7) } src="images/proovedores/proovedores-09.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="7" />
                            <img onClick={ () => handleShow(5) } src="images/proovedores/proovedores-07.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="5" />
                        </div>
                        <div className="column">
                            <img onClick={ () => handleShow(6) } src="images/proovedores/proovedores-08.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="6" />
                            <img onClick={ () => handleShow(8) } src="images/proovedores/proovedores-06.jpg" className="d-block" alt="Proovedores de Irunfal" data-img-mostrar="8" />
                            <img onClick={ () => handleShow(9) } src="images/proovedores/proovedores-10.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="9" />
                            <img onClick={ () => handleShow(10) } src="images/proovedores/proovedores-11.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="11" />
                            <img onClick={ () => handleShow(11) } src="images/proovedores/proovedores-12.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="12" />
                            <img onClick={ () => handleShow(12) } src="images/proovedores/proovedores-13.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="13" />
                            <img onClick={ () => handleShow(13) } src="images/proovedores/proovedores-14.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="14" />
                        </div>
                        <div className="column">
                            <img onClick={ () => handleShow(14) } src="images/proovedores/proovedores-15.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="15" />
                            <img onClick={ () => handleShow(15) } src="images/proovedores/proovedores-16.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="16" />
                            <img onClick={ () => handleShow(16) } src="images/proovedores/proovedores-17.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="17" />
                            <img onClick={ () => handleShow(17) } src="images/proovedores/proovedores-18.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="18" />
                            <img onClick={ () => handleShow(18) } src="images/proovedores/proovedores-19.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="19" />
                            <img onClick={ () => handleShow(19) } src="images/proovedores/proovedores-20.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="20" />
                            <img onClick={ () => handleShow(20) } src="images/proovedores/proovedores-21.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="21" />
                        </div>
                        
                        <div className="column">
                            <img onClick={ () => handleShow(21) } src="images/proovedores/proovedores-22.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="22" />
                            <img onClick={ () => handleShow(22) } src="images/proovedores/proovedores-23.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="23" />
                            <img onClick={ () => handleShow(23) } src="images/proovedores/proovedores-24.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="24" />
                            <img onClick={ () => handleShow(24) } src="images/proovedores/proovedores-25.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="25" />
                            <img onClick={ () => handleShow(25) } src="images/proovedores/proovedores-26.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="26" />
                            <img onClick={ () => handleShow(26) } src="images/proovedores/proovedores-27.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="27" />
                            <img onClick={ () => handleShow(27) } src="images/proovedores/proovedores-28.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="28" />
                            <img onClick={ () => handleShow(28) } src="images/proovedores/proovedores-29.jpg" className ="d-block" alt="Proovedores de Irunfal" data-img-mostrar="29" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default GalleryProovedores