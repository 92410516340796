import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import LogoFooter from '../assets/logo-footer.svg'
import FooterFormulario from './common/FooterFormulario'

const Footer = () => {
  return (
    <Container className='footer__container' fluid>
        <Container className='pb-5'>
          <Row className='justify-content-lg-center'>
            <FooterFormulario />
            <hr className='m-5'/>
          </Row>
        </Container>
        <Row className='justify-content-lg-center'>
          <Col column="md" lg={2} className='footer__logo--position'>             
              <img src={LogoFooter} className='footer__logo' alt="Harispe Agrimensura" />
          </Col>
          <Col column="md" lg={4} className='pt-3 footer__contact'>
          
            <div className='footer__textBlock'>
              <span className='footer__icon'><i className="fa-solid fa-location-dot"></i></span>
              <span><p className='footer__text'> Juan Carlos Gomez 1348, P6,<br/>Montevideo, Uruguay.</p> </span>
            </div>
            <div className='footer__textBlock'>
              <span className='footer__icon'><i className="fa-solid fa-mobile-screen"></i></span>
              <span><p className='footer__text'> 00598 29153022 </p> </span>
            </div>
            
            <div className='footer__textBlock'>
              <span className='footer__icon'><i className="fa-solid fa-envelope"></i></span>
              <span><p className='footer__text'> agaleano@galeanoabogados.com </p> </span>
            </div>
          </Col>
          <Col column="md" lg={2} className='pt-3 footer__linkBlock'>             
              <p className='footer__title '>Accesos</p>
              <Link to={'quienes-somos'}> <span><p className='footer__text footer__link'>Quienes somos</p> </span></Link>
              <Link to={'servicios'}> <span><p className='footer__text footer__link'>Servicios </p> </span></Link>
              <Link to={'proovedores'}> <span><p className='footer__text footer__link'>Proovedores</p> </span></Link>
              
          </Col>
          <Col column="md" lg={2} className='pt-3 footer__linkBlock'>             
              <p className='footer__title'><br></br></p>
              <Link to={'clientes'}> <span><p className='footer__text footer__link'>Clientes</p> </span></Link>
              <Link to={'contacto'}> <span><p className='footer__text footer__link'>Contacto </p> </span></Link>
          </Col>
          
        </Row>
        <Row className='footer__containerCopy'>
            <p className='footer__copy'>Irunfal  -  2018. Todos los derechos reservados.</p>
        </Row>
    </Container>
  )
}

export default Footer