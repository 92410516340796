import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import Header from '../common/Header'

const Servicios = () => {
  return (
    
    <Container fluid className='p-0' >
      
      <Header urlImg='./images/headers/servicios.jpg' titulo='Servicios'/>
      
      <Container className='section__container'>
        <Row className='section__container--row mb-3'>
          <Col xs={12} lg={8} className='section__container--col'>
            <p className='qsomos__text--bloque'>
            En IRUNFAL trabajamos para que empresas de todos los volúmenes reciban sus compras Online desde China, Turquía e India con total seguridad a toda la región.</p>
          </Col>
        </Row>
        <Row className='pt-4'>
          <Col xs={12} lg={6} className='bloque__img bloque__img--start p-0'>
            <img src="./images/servicios-importacion.jpg" alt="Servicios para nuestros clientes." />
          </Col>
          
          <Col xs={12} lg={6}  className='qsomos__img qsomos__imgServicios'>
            <p className='qsomos__text--bloque '>
              <strong>•	Atención personalizada Integral<br/>
              •	Gestión de trámites aduaneros<br/>
              •	Cálculos y factibilidad<br/>
              •	Servicios legales<br/>
              •	Asesoría tributaria<br/>
              •	Logística Internacional<br/>
              •	Rastreo de envíos<br/>
              </strong>

            </p>
          </Col>
         
        </Row>
      </Container>

        <Col  className='bloque__img bloque__img--start'>
            <p className='banner__text banner__text--black'>Nuestros clientes compran y venden desde y hacia el exterior. Nosotros nos encargamos del resto.</p>
          </Col>
    </Container>

  )
}

export default Servicios