import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import Banner from '../common/Banner'
import Header from '../common/Header'

const QuienesSomos = () => {
  return (

    <Container fluid className='p-0' >
      <Header urlImg='./images/headers/quienes-somos.jpg' titulo='Quienes sosmos'/>
        <Container className='section__container'>
          <Row className='section__container--row'>
            <Col xs={12} lg={6} className='section__container--col'>
              <p className='qsomos__text--bloque'>
                Somos una compañía especializada en importación y exportación de máquinas e insumos para el mercado de electrodomésticos y refrigeración.
                Gracias a nuestra trayectoria de más de 15 años, hemos podido brindar una amplia gama de servicios a las empresas de del rubro de toda la región. <br /><br />
                IRUNFAL ofrece servicios de excelencia a precios internacionales, logística y seguimiento de los negocios, asistencia personalizada y asesoramiento permanente.<br /><br />
                <strong>IRUNFAL actúa como la oficina comercial de empresas del exterior desde Uruguay para toda América latina.</strong><br /><br />
                Por tener un mayor conocimiento del desarrollo del mercado, nuestra empresa puede ofrecerle una permanente búsqueda de proveedores y productos, manteniéndolos continuamente informados sobre las nuevas tendencias del mercado.
              </p>
            </Col>
            
            <Col xs={12} lg={6}  className='qsomos__img'>
              <img src="./images/quienes-somos.jpg" alt="Somos Irunfal SA trabajando." />
            </Col>
          </Row>
        </Container>
        <Container fluid className='p-0' >
          <Banner />
        </Container>
        <Container className='section__container'>
          <Row className='section__container--row'>
            <Col xs={12} lg={6}  className='qsomos__img'>
              <img src="./images/quienes-somos-mision.jpg" alt="Somos Irunfal SA trabajando." />
            </Col>
            <Col xs={12} lg={6} className='section__container--col'>
            <h3 className='bloque__titulo mb-4'>MISIÓN</h3>
              <p className='qsomos__text--bloque'>
                Para nuestra empresa, el SERVICIO AL CLIENTE
                tiene prioridad y cada día trabajamos para llegar a la excelencia.<br /><br />
                Clientes y proveedores son nuestros socios, y a ellos va dirigido todo nuestro esfuerzo.
                <br /><br />
              </p>
            </Col>
          </Row>
        </Container>
    </Container>
    
  )
}

export default QuienesSomos