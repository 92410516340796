import React from 'react'
import { Container } from 'react-bootstrap'

const HeaderMap = ( ) => {
  
  return (

    <Container fluid className='p-0 header__container animated fast fadeIn' style={{ backgroundColor: "rgba(0,0,0,0.1)"}}>
        
        <div className='contact__map'>
          <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.9821164914824!2d-56.202734899999996!3d-34.90689830000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f802a629fbc25%3A0xa7c1d9292a020883!2sp6%2C%20Juan%20Carlos%20G%C3%B3mez%201348%2C%2011000%20Montevideo%2C%20Departamento%20de%20Montevideo%2C%20Uruguay!5e0!3m2!1ses-419!2sar!4v1659290562651!5m2!1ses-419!2sar" 
          width="100%" 
          height="450"
          style={{ border: '0', marginTop: '100px'}}
          loading="lazy" 
          title='Irunfal SA'/>
      </div>
        
    </Container>

  )
}

export default HeaderMap